import { Component, Inject, OnInit } from '@angular/core';
import { MaterialModule } from '../../material/material.module';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-tridy-dialog',
  standalone: true,
  imports: [MaterialModule, CommonModule],
  template: `

    <h1 mat-dialog-title>Tridy Auswahl</h1>
    <div mat-dialog-content style="overflow:hidden;">
      <div style="position:relative;">

      <p>Bitte wähle ein Tridy aus:</p>
        <div class="task-toolbar d-flex justify-content-between align-items-center">
          
          <mat-form-field appearance="fill" class="mx-3 w-100">
            <input
              matInput
              placeholder="Tridys durchsuchen"
              matTooltip="Suche nach ID oder spezifischen Schlüsseln."
              [(ngModel)]="searchTerm"
              (input)="filterTridys()" />
            <mat-icon matPrefix>search</mat-icon>
          </mat-form-field>
          <!-- 
            <div class="mb-4 mx-3">
              <button disabled mat-icon-button [matMenuTriggerFor]="filterMenu">
                <mat-icon>tune</mat-icon>
              </button>
              <mat-menu #filterMenu="matMenu">
                <button mat-menu-item>
                  <mat-slide-toggle>Gruppen anzeigen</mat-slide-toggle>
                </button>
                filter for types (transform, edit, create)
                custom filter by
              </mat-menu>
            </div>
          -->
        </div>
      </div>

      <section class="my-2" style="overflow: auto;height: 440px;">

        <div class="tridy-list">
          @if (tridys.length > 0) {
            <div class="list-container" style="margin-bottom: 50px">
              <!--  @for (template of workTaskTemplates; track template.id) { -->
              @for (tridy of tridys; track tridy.id) {
                <div
                  class="list-item"
                  (click)="selectTridy(tridy.id)"
                  matTooltipShowDelay="1500"
                  matTooltip="Tridy ID: {{ tridy.id }}"
                >
              <!-- <mat-list-item (click)="selectContext(context.id)"> -->
                <div class="p-2">
                  @if (titleKey){
                    <div class="title mb-1"><strong>{{ tridy.payload?.[titleKey] }}</strong></div>
                  }
                  @else {
                    <div class="title mb-1"><strong>{{ tridy.id }}</strong></div>
                  }
                  @if (subtitleKeys){
                    @for (subtitleKey of subtitleKeys; track subtitleKey) {
                      <div class="subtitle"><strong>{{ tridy.payload?.[subtitleKey] }}</strong></div>
                    }
                    
                  }
                  @if (tridy.creationDate) {
                    <div class="details d-flex align-items-center"><mat-icon>access_time</mat-icon> {{ tridy.creationDate.toDate() | date: 'dd.MM.yyyy - HH:mm:ss' }}</div>
                  }
                  @if (tridy.timeStamp) {
                    <div class="details d-flex align-items-center"><mat-icon>update</mat-icon> {{ tridy.timeStamp.toDate() | date: 'dd.MM.yyyy - HH:mm:ss' }}</div>
                  }
                </div>
                </div>
              }
            </div>
          }
        </div>
      </section>

    </div>
    <div mat-dialog-actions>
      <button mat-button style="color:var(--warmOrange);" (click)="closeDialog()">Abbrechen</button>
    </div>
  `,
  styles: [`
    .list-item {
      cursor: pointer;
      strong{
        color: var(--narraveroBlue);
      }
    }
    .list-item:hover {
      background-color: var(--highlightOrange);
      .title strong{
        color: var(--warmOrange)!important;
      }
    }
    .tridy-list {
      .title{
        font-size:16px;
        color: var(--warmOrange)!important;
      }
      .details{
        font-size:12px;
        color:gray;
        mat-icon{
          font-size:16px;
          height:16px;
        }
      }
      .subtitle{
        font-size:13px;
        color:gray;
      }
    }
  `]
})

export class TridySelectionComponent implements OnInit {
  originalTridys: any[] = []; // Store the original list of tridys
  tridys: any[] = []; // This will be the filtered list shown in the UI
  searchTerm: string = '';
  titleKey: string | null = null;
  subtitleKeys: string[] | null = null;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialogRef: MatDialogRef<TridySelectionComponent>
  ) {}

  ngOnInit(): void {
    // Initialize both the original and working copies of tridys
    this.originalTridys = [...this.dialogData.tridys]; // Clone to keep it immutable
    this.tridys = [...this.dialogData.tridys].sort((a, b) => {
      // Convert Firebase Timestamps to JavaScript Date
      const timeA = a.timeStamp?.toDate() || 0;
      const timeB = b.timeStamp?.toDate() || 0;
      // Compare time values
      return timeB.getTime() - timeA.getTime(); // Desc
    });

      const titleKey = this.dialogData.titleKey || null;
      if (titleKey) {
        this.titleKey = titleKey
        //console.log('Received Title Key:', titleKey);
      } else {
        console.warn('No Title Key received');
      }

      const subtitleKeys = this.dialogData.subtitleKeys || null;
      if (subtitleKeys) {
        this.subtitleKeys = subtitleKeys
        //console.log('Received Subtitle Keys:', subtitleKeys);
      } else {
        console.warn('No Subtitle Keys received');
      }
  
    //console.log("Sorted Tridys:", this.tridys);
  }  


/*   filterTridys(): void {
    const term = this.searchTerm.toLowerCase();

    if (term) {
      // Filter the original list based on the search term
      this.tridys = this.originalTridys.filter((tridy) => {
        return (
          tridy.id?.toLowerCase().includes(term) || 
          Object.values(tridy.payload || {}).some((value: any) =>
            String(value).toLowerCase().includes(term)
          )
        );
      });
    } else {
      // Reset to the original list
      this.tridys = [...this.originalTridys];
    }
  } */

  filterTridys(): void {
    const term = this.searchTerm.toLowerCase();

    if (term) {
      // Filter the original list based on the search term
      this.tridys = this.originalTridys.filter((tridy) => {
        const title = tridy.payload?.[this.titleKey || ''];
        const subtitle = this.subtitleKeys?.map((key) => tridy.payload?.[key]).join(' ') || '';
        return (
          tridy.id?.toLowerCase().includes(term) || 
          (title && title.toLowerCase().includes(term)) || 
          subtitle.toLowerCase().includes(term)
        );
      });
    } else {
      // Reset to the original list
      this.tridys = [...this.originalTridys];
    }
  }
  selectTridy(tridyId: string): void {
    this.dialogRef.close(tridyId);
  }

  closeDialog(): void {
    this.dialogRef.close(null);
  }

}
