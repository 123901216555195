import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { DataService } from '../../services/data.service';
import { MaterialModule } from '../../material/material.module';
import { WorkTaskFormComponent } from "../work-task-form/work-task-form.component";
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-work-task-list',
  standalone: true,
  imports: [MaterialModule, WorkTaskFormComponent],
  templateUrl: './work-task-list.component.html',
  styleUrl: './work-task-list.component.scss'
})
export class WorkTaskListComponent implements OnInit {

  userDisplayName: string = '';
  userData:any;
  workTaskTemplates: any[] = [];
  selectedTask: any = null;
  currentTaskId: string | null = null;
  filteredTemplates: any[] = [];
  searchTerm: string = '';
  disableSearch: boolean = false;

  constructor(
    public authService: AuthService,
    public dataService: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService
  ) {}


  ngOnInit(): void {

    // Get the current route task ID
    this.route.paramMap.subscribe((params) => {
      this.currentTaskId = params.get('id'); // Extract the task ID from the route
    });
    // Check for locally saved context ID
    const savedContextId = this.dataService.getLocalContextId();
    if (savedContextId) {
      //console.log('Using saved Context ID:', savedContextId);
      this.fetchWorkTaskTemplates(savedContextId); // Fetch templates for the saved context ID
    } else {
      //console.log('No saved Context ID found, subscribing to user and context changes.');

      // Subscribe to the authenticated user
      this.authService.user$.subscribe((user) => {
        if (user) {
          this.userData = user;
          this.userDisplayName = user.displayName || '';

          this.dataService.getContextIdOfUser(user.uid);

          this.dataService.contextIdChange$.subscribe((contextId) => {
            if (contextId) {
              //console.log('Context ID resolved:', contextId);
              this.fetchWorkTaskTemplates(contextId); 

              this.dataService.saveContextId(contextId);
            } else {
              this.notificationService.showError('Fehler: Kein Konto verfügbar.');
              console.warn('No context ID available for user');
            }
          });
        } else {
          this.userDisplayName = '';
        }
      });
    }
  }

  getTaskById(taskId: any): any {
    return this.workTaskTemplates.find((task) => task.id === taskId);
  }

  // Fetch and auto-select
  fetchWorkTaskTemplates(contextId: string): void {
    this.dataService.getWorkTaskTemplateData(contextId).subscribe(
      (templates) => {

        templates.sort((a, b) => {
          if (a.sortIndex !== undefined && b.sortIndex !== undefined) {
            return (a.sortIndex ?? 1000) - (b.sortIndex ?? 1000);
          } else {
            const dateA = a.timeStamp ? a.timeStamp.toDate().getTime() : 0; // Default to 0 if undefined
            const dateB = b.timeStamp ? b.timeStamp.toDate().getTime() : 0; // Default to 0 if undefined
            return dateB - dateA; // Sort descending
          }
        });

        this.workTaskTemplates = templates;
        this.filteredTemplates = templates; // Initialize filtered list with all templates

        // Auto-select the task and disable search if only one exists
        if (templates.length === 1 || templates.length === 0) {
          this.disableSearch = true;
          this.onSelectTask(templates[0]);
        }

        // Auto-select if the task ID is provided in the route
        if (this.currentTaskId) {
          const selectedTask = this.getTaskById(this.currentTaskId);
          if (selectedTask) {
            this.onSelectTask(selectedTask);
          }
        }

      },
      (error) => {
        console.error('Error fetching work task templates:', error);
        this.notificationService.showError('Fehler: Vorlagen konnten nicht geladen werden.');
      }
    );
  }

  onSelectTask(task: any): void {
    this.dataService.selectTask(task); // Notify DataService about the selected task
    this.router.navigate(['/tasks', task.id]); // Navigate to the task form with the task ID
  }

  isTaskActive(taskId: string): boolean {
    return this.currentTaskId === taskId; // Compare the route task ID with the task ID
  }

  filterTemplates(): void {
    const term = this.searchTerm.toLowerCase();
    this.filteredTemplates = this.workTaskTemplates.filter(
      (template) => template.title?.toLowerCase().includes(term)
    );
  }
}
