import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../services/auth.service';
import { DataService } from '../../services/data.service';
import { MaterialModule } from '../../material/material.module';
import { FinishedTaskDetailComponent } from "../finished-task-detail/finished-task-detail.component";
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-finished-task-list',
  standalone: true,
  imports: [CommonModule, MaterialModule, FinishedTaskDetailComponent],
  templateUrl: './finished-task-list.component.html',
  styleUrl: './finished-task-list.component.scss'
})
export class FinishedTaskListComponent implements OnInit {

  userDisplayName: string = '';
  userData:any;
  finishedTasks: any[] = [];
  selectedTask: any = null;
  currentTaskId: string | null = null;
  filteredTemplates: any[] = [];
  searchTerm: string = '';
  disableSearch:boolean=false;
  isLoading:boolean=false

  constructor(
    public authService: AuthService,
    public dataService: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    // Get the current route task ID
    this.route.paramMap.subscribe((params) => {
      this.currentTaskId = params.get('id'); // Extract the task ID from the route
    });

    // Check for locally saved context ID
    const savedContextId = this.dataService.getLocalContextId();
    if (savedContextId) {
      //console.log('Using saved Context ID:', savedContextId);
      this.fetchFinishedTasks(savedContextId); // Fetch templates for the saved context ID
    } else {
      //console.log('No saved Context ID found, subscribing to user and context changes.');

      // Subscribe to the authenticated user
      this.authService.user$.subscribe((user) => {
        if (user) {
          this.userData = user;
          this.userDisplayName = user.displayName || '';

          this.dataService.getContextIdOfUser(user.uid);

          this.dataService.contextIdChange$.subscribe((contextId) => {
            if (contextId) {
              //console.log('Context ID resolved:', contextId);
              this.fetchFinishedTasks(contextId); 

              this.dataService.saveContextId(contextId);
            } else {
              this.notificationService.showError('Fehler: Kein Konto verfügbar.');
              console.warn('No context ID available for user');
            }
          });
        } else {
          this.userDisplayName = '';
        }
      });
    }
  }

  getTaskById(taskId: any): any {
    return this.finishedTasks.find((task) => task.id === taskId);
  }

  fetchFinishedTasks(contextId: string): void {
    this.dataService.getFinishedTasksData(contextId).subscribe(
      (tasks) => {
        this.finishedTasks = tasks.sort((a, b) => {
          const dateA = a.timeStamp ? a.timeStamp.toDate().getTime() : 0; // Default to 0 if undefined
          const dateB = b.timeStamp ? b.timeStamp.toDate().getTime() : 0; // Default to 0 if undefined
          return dateB - dateA; // Sort descending
        });
        this.filteredTemplates = this.finishedTasks; // Initialize filtered list with all templates

        // Auto-select if the task ID is provided in the route
        if (this.currentTaskId) {
          const selectedTask = this.getTaskById(this.currentTaskId);
          if (selectedTask) {
            this.onSelectFinishedTask(selectedTask);
          }
        }
  
        // Disable search if only one exists
        if (tasks.length === 1 || tasks.length === 0) {
          this.disableSearch = true;
        }
        this.isLoading = false;
      },
      (error) => {
        console.error('Error fetching finished tasks:', error);
        this.notificationService.showError('Fehler: Erledigte Aufgaben konnten nicht geladen werden.');
        this.isLoading = false;
      }
    );
  }
  

    onSelectFinishedTask(task: any): void {
      this.dataService.selectFinishedTask(task); // Notify DataService about the selected task
      this.router.navigate(['/finished-tasks', task.id]); // Navigate to the task form with the task ID
    }

    isFinishedTaskActive(taskId: string): boolean {
      return this.currentTaskId === taskId; // Compare the route task ID with the task ID
    }

    filterTemplates(): void {
      const term = this.searchTerm.toLowerCase();
    
      // Filter based on multiple fields
      this.filteredTemplates = this.finishedTasks.filter((task) => {
        return (
          task.title?.toLowerCase().includes(term) || 
          task.id?.toLowerCase().includes(term) || 
          task.tridyId?.toLowerCase().includes(term) || 
          Object.values(task.payload || {}).some((value: any) =>
            String(value).toLowerCase().includes(term) 
          )
        );
      });
    }
}
