import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {

  constructor(private snackBar: MatSnackBar) {
  }

  showSuccess(message: string, duration: number = 4000): void {
    this.snackBar.open(message, 'OK', {
      duration,
      //panelClass: ['success-snackbar'],
    });
  }

  showError(message: string, duration: number = 4000): void {
    this.snackBar.open(message, 'OK', {
      duration,
      panelClass: ['error-snackbar'],
    });
  }

  showInfo(message: string, duration: number = 4000): void {
    this.snackBar.open(message, 'OK', {
      duration,
      //panelClass: ['info-snackbar'],
    });
  }
}
