<div class="form-wrapper p-4">

  @if(tridyData){

    @if(isLoading) {
      <div class="spinner">
        <mat-progress-spinner color="accent" mode="indeterminate"></mat-progress-spinner>
        <p class="mt-4">Laden...</p>
      </div>
    }
  
    @if(!isLoading) {

      <h4 style="color: var(--warmOrange);">Details</h4>
      <div class="my-2">
        Erstellt am:
        @if(tridyData?.creationDate) {
          {{ tridyData.creationDate.toDate() | date: 'dd.MM.yyyy, HH:mm:ss' }}
        }
        @if(tridyData?.timeStamp) {
          <br/>
          Zulezt geändert am:
          {{ tridyData.timeStamp.toDate() | date: 'dd.MM.yyyy, HH:mm:ss' }}
        }
      </div>

      <div class="mt-4">
        @if(tridyData?.id) {
          <div><strong>ID:</strong> {{tridyData.id}}</div>
        }
        @if(tridyData?.passURL) {
          <div class="mt-3">
            <button mat-button class="neutral-button" matTooltipShowDelay="1200" matTooltip="In neuem Fenster öffnen" (click)="openQRCode(tridyData.passURL)">
              <mat-icon>qr_code</mat-icon> QR Code
            </button>
            <button mat-button class="neutral-button" matTooltipShowDelay="1200" matTooltip="In die Zwischenablage kopieren" (click)="copyToClipboard(tridyData.passURL)">
              <mat-icon>content_copy</mat-icon> URL kopieren
            </button>
          </div>
        }
        <!-- show action? print?-->
      </div>

      <mat-divider class="my-4"></mat-divider>
      <div class="my-4 payload-wrapper">
        @if (tridyData?.payload) {
          <!-- Standard fields -->
          <h5 class="mb-4"><mat-icon>assignment</mat-icon> Payload Daten</h5>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th style="width: 50%;">Schlüssel</th>
                <th style="width: 50%;">Wert</th>
              </tr>
            </thead>
            <tbody>
              @for (key of standardFieldKeys; track key) {
                <tr>
                  <td style="width: 50%;">{{ key }}</td>
                  <td style="width: 50%;">
                    @if (tridyData.payload[key]?.toDate) {
                      {{ tridyData.payload[key].toDate() | date: 'dd.MM.yyyy, HH:mm:ss' }}
                    }
                    @else {
                      {{ tridyData.payload[key] || '' }}
                    }
                  </td>
                </tr>
              }
            </tbody>
          </table>
        
          <!-- Upload fields -->
          @if (uploadFieldKeys.length > 0) {
            <div class="mt-4">
              <h5 class="mb-4"><mat-icon>attach_file</mat-icon> Hochgeladen</h5>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th style="width: 50%;">Schlüssel</th>
                    <th style="width: 50%;">Dateiname</th>
                  </tr>
                </thead>
                <tbody>
                  @for(key of uploadFieldKeys; track key) {
                    <tr>
                      <td style="width: 50%;">{{ key }}</td>
                      <td style="width: 50%;">
                        @if(tridyData.payload[key]?.fileName) {
             <!--              <a
                            href="{{ getDownloadLink(key) }}"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="In neuer Tab öffnen"
                          >
                            {{ taskData.payload[key].fileName }}
                          </a> -->
                          {{ tridyData.payload[key].fileName }}
                        }
                        @else {
                          Image File
                        }
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          }
        }

        @if (tridyData?.['workTasks.tasks']) {
          <!-- Standard fields -->
          <h5 class="my-4"><mat-icon>history</mat-icon> Verlauf</h5>
          
            @for (task of tridyData?.['workTasks.tasks']; track task) {
              <div>
                <button
                  matTooltip="{{task.id}}"
                  matTooltipShowDelay="2000"
                  class="neutral-button mb-1"
                  mat-button
                  (click)="navigateTo('/finished-tasks/' + task.id)"
                >
                  <mat-icon>task-done</mat-icon>
                  {{ task.timeStamp.toDate() | date: 'dd.MM.yyyy, HH:mm:ss' }}
                </button>
              </div>

            }
        
        }
        @else {
          <div class="d-flex justify-content-center" style="width:100%;height:100%;">
            <p style="align-self:center;">Kein Verlauf vorhanden. Dieser Tridy wurde möglicherweise importiert oder manuell erstellt.</p>
          </div>
        }

      </div>
      
    }

  }
  @else {
    <div style="overflow: scroll;height: 99%;">
      <div class="d-flex justify-content-center" style="width:100%;height:100%;">
        <p style="align-self:center;">Nichts ausgewählt</p>
      </div>
    </div>
  }

</div>
