import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../services/auth.service';
import { DataService } from '../../services/data.service';
import { MaterialModule } from '../../material/material.module';
import { TridyDetailComponent } from "../tridy-detail/tridy-detail.component";
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../services/notification.service';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-tridy-list',
  standalone: true,
  imports: [MaterialModule, CommonModule, ReactiveFormsModule, MatSelectModule, TridyDetailComponent],
  templateUrl: './tridy-list.component.html',
  styleUrl: './tridy-list.component.scss'
})
export class TridyListComponent implements OnInit {

  userDisplayName: string = '';
  userData:any;
  tridyList: any[] = [];
  selectedTridy: any = null;
  currentTridyId: string | null = null;
  filteredTridys: any[] = [];
  searchTerm: string = '';
  disableSearch: boolean = false;
  filterForm: FormGroup;
  isLoading:boolean=false

  sortKey: string = 'name';
  filterKey: string = '';

  constructor(
    public authService: AuthService,
    private fb: FormBuilder,
    public dataService: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService
  ) {
    this.filterForm = this.fb.group({
      sortKey: ['name'], // Default sorting field
    });
  }

  ngOnInit(): void {

    this.isLoading = true;
    // Get the current route tridy ID
    this.route.paramMap.subscribe((params) => {
      this.currentTridyId = params.get('id'); // Extract the tridy ID from the route
    });
    // Check for locally saved context ID
    const savedContextId = this.dataService.getLocalContextId();
    if (savedContextId) {
      //console.log('Using saved Context ID:', savedContextId);
      this.fetchTridyList(savedContextId);
    } else {
      //console.log('No saved Context ID found, subscribing to user and context changes.');

      // Subscribe to the authenticated user
      this.authService.user$.subscribe((user) => {
        if (user) {
          this.userData = user;
          this.userDisplayName = user.displayName || '';

          this.dataService.getContextIdOfUser(user.uid);

          this.dataService.contextIdChange$.subscribe((contextId) => {
            if (contextId) {
              //console.log('Context ID resolved:', contextId);
              this.fetchTridyList(contextId); 

              this.dataService.saveContextId(contextId);
            } else {
              this.notificationService.showError('Fehler: Kein Konto verfügbar.');
              console.warn('No context ID available for user');
            }
          });
        } else {
          this.userDisplayName = '';
        }
      });
    }

    // Initially display all items
    this.filteredTridys = [...this.tridyList];
  }

  getTridyById(tridyId: any): any {
    return this.tridyList.find((tridy) => tridy.id === tridyId);
  }

  fetchTridyList(contextId: string): void {
    this.dataService.getAllTridys(contextId).then(
      (tridys) => {
        this.tridyList = tridys.sort((a, b) => {
          const dateA = a.timeStamp ? a.timeStamp.toDate().getTime() : 0; // Default to 0 if undefined
          const dateB = b.timeStamp ? b.timeStamp.toDate().getTime() : 0; // Default to 0 if undefined
          return dateB - dateA; // Sort descending
        });
        this.filteredTridys = this.tridyList; // Initialize filtered list with all templates

        // Auto-select if the tridy ID is provided in the route
        if (this.currentTridyId) {
          const selectedTridy = this.getTridyById(this.currentTridyId);
          if (selectedTridy) {
            this.onSelectTridy(selectedTridy);
          }
        }
  
        // Disable search if only one exists
        if (tridys.length === 1 || tridys.length === 0) {
          this.disableSearch = true;
        }
        setTimeout(() => {
          this.isLoading = false;
        }, 400);
      },
      (error) => {
        this.isLoading = false;
        console.error('Error fetching tridys:', error);
        this.notificationService.showError('Fehler: Tridys konnten nicht geladen werden.');
      }
    );
  }

  onSelectTridy(tridy: any): void {
    this.dataService.selectTridy(tridy);
    this.router.navigate(['/tridys', tridy.id]);
  }

  isTridyActive(tridyId: string): boolean {
    return this.currentTridyId === tridyId;
  }

  filterTridys(): void {
    const term = this.searchTerm.toLowerCase();
  
    // Filter based on multiple fields
    this.filteredTridys = this.tridyList.filter((tridy) => {
      return (
        tridy.id?.toLowerCase().includes(term) || 
        Object.values(tridy.payload || {}).some((value: any) =>
          String(value).toLowerCase().includes(term) 
        )
      );
    });
  }
    
  applyFilters(): void {
    const { sortKey } = this.filterForm.value;
    // Sort by selected key
    if (sortKey) {
      this.filteredTridys = this.filteredTridys.sort((a, b) => {
        const aValue = a[sortKey] || '';
        const bValue = b[sortKey] || '';
        return aValue > bValue ? 1 : -1;
      });
    }
  }
  
  resetFilter(): void {
    // Reset form values and restore original list
    this.filterForm.reset({
      sortKey: 'name',
    });
    this.filteredTridys = [...this.tridyList];
  }
  
  trackById(index: number, item: any): string {
    return item.id;
  }
    
}
