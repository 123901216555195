<div class="form-wrapper p-4">

  @if(taskData){

    @if(isLoading) {
      <div class="spinner">
        <mat-progress-spinner color="accent" mode="indeterminate"></mat-progress-spinner>
        <p class="mt-4">Laden...</p>
      </div>
    }
  
    @if(!isLoading) {

      <h4 style="color: var(--warmOrange);">{{taskData.title}}:</h4>
      <div class="my-2">
        Abgeschlossen am:
        @if(taskData?.timeStamp) {
          {{ taskData.timeStamp.toDate() | date: 'dd.MM.yyyy, HH:mm:ss' }}
        }
      </div>

      <div class="mt-4">
        @if(taskData?.id) {
          <div><strong>ID:</strong> {{taskData.id}}</div>
        }
        @if(taskData?.tridyId) {
          <div><strong>Tridy ID:</strong> {{taskData.tridyId}}</div>
        }
        <!-- show action? print?-->
      </div>

      <mat-divider class="my-4"></mat-divider>
      <div class="my-4 payload-wrapper">
        @if (taskData?.payload) {
          <!-- Standard fields -->
          <h5 class="mb-4"><mat-icon>assignment</mat-icon> Payload Daten</h5>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th style="width: 50%;">Schlüssel</th>
                <th style="width: 50%;">Wert</th>
              </tr>
            </thead>
            <tbody>
              @for (key of standardFieldKeys; track key) {
                <tr>
                  <td style="width: 50%;">{{ key }}</td>
                  <td style="width: 50%;">
                    @if (taskData.payload[key]?.toDate) {
                      {{ taskData.payload[key].toDate() | date: 'dd.MM.yyyy, HH:mm:ss' }}
                    }
                    @else {
                      {{ taskData.payload[key] || '' }}
                    }
                  </td>
                </tr>
              }
            </tbody>
          </table>
        
          <!-- Upload fields -->
          @if (uploadFieldKeys.length > 0) {
            <div class="mt-4">
              <h5 class="mb-4"><mat-icon>attach_file</mat-icon> Hochgeladen</h5>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th style="width: 50%;">Schlüssel</th>
                    <th style="width: 50%;">Dateiname</th>
                  </tr>
                </thead>
                <tbody>
                  @for(key of uploadFieldKeys; track key) {
                    <tr>
                      <td style="width: 50%;">{{ key }}</td>
                      <td style="width: 50%;">
                        @if(taskData.payload[key]?.fileName) {
                          <a
                            href="{{ getDownloadLink(key) }}"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="In neuer Tab öffnen"
                          >
                            {{ taskData.payload[key].fileName }}
                          </a>
                        }
                        @else {
                          Image File
                        }
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          }
        }
      </div>
      
    }

  }
  @else {
    <div style="overflow: scroll;height: 99%;">
      <div class="d-flex justify-content-center" style="width:100%;height:100%;">
        <p style="align-self:center;">Nichts ausgewählt</p>
      </div>
    </div>
  }

</div>
