import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../services/auth.service';
import { DataService } from '../../services/data.service';
import { MaterialModule } from '../../material/material.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { NotificationService } from '../../services/notification.service';
import { HelperService } from '../../services/helper.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tridy-detail',
  standalone: true,
  imports: [CommonModule, MaterialModule, MatDatepickerModule, MatSelectModule],
  templateUrl: './tridy-detail.component.html',
  styleUrl: './tridy-detail.component.scss'
})
export class TridyDetailComponent implements OnInit {

  tridyData: any = null;
  isLoading:boolean=false

  standardFieldKeys: string[] = [];
  uploadFieldKeys: string[] = [];
  hostname = environment.urlHostName
    
  constructor(
    private route: ActivatedRoute,
    public authService: AuthService,
    public dataService: DataService,
    private router: Router,
    private helperService: HelperService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.route.paramMap
      .pipe(
        switchMap((params) => {
          const tridyId = params.get('id');
          if (tridyId) {
            //console.log('Tridy ID:', tridyId);
            return this.dataService.getSelectedTridy().pipe(
              switchMap((tridy) => {
                if (tridy?.id === tridyId) {
                  return [tridy]; // Task is already selected
                } else {
                  return [this.dataService.getTridyById(tridyId)];
                }
              }),
              catchError((error) => {
                console.error('Error fetching task:', error);
                this.notificationService.showError('Fehler: Daten konnten nicht geladen werden.');
                return of(null); // null in case of error
              })
            );
          }
          return of(null); // Handle if no tridyId
        })
      )
      .subscribe((tridy) => {
        if (tridy) {
          this.populateTridyData(tridy);
        } else {
          //console.warn('No task found or error occurred');
          this.tridyData = null;
        }
        this.isLoading = false;
      });
  }
  
  populateTridyData(tridy: any): void {
    this.tridyData = tridy;

    const payload = tridy.payload || {};
    this.standardFieldKeys = Object.keys(payload).filter(
      (key) =>
        typeof payload[key] !== 'object' || 
        (!payload[key]?.fileName && !payload[key]?.contentType)
    );
    this.uploadFieldKeys = Object.keys(payload).filter(
      (key) => payload[key]?.fileName || payload[key]?.contentType
    );
  }

  openQRCode(passURL: string): void {
    this.helperService.openQRCode(passURL);
  }


  copyToClipboard(passURL: string): void {
    navigator.clipboard.writeText(passURL).then(() =>
      this.notificationService.showSuccess('URL kopiert')
    );
  }

  navigateTo(url: string): void {
    this.router.navigate([url]);
  }

  //importedData missing

}
